import React from "react";
import { UserImage } from "./user-image";


export function User() {
    return (
        <div className="position-relative">
            <UserImage />
        </div>
    );
}
