import React, { useCallback, useEffect, useRef, useState } from "react";
import { api } from "../../api";
import { Flex } from "../../components/flex";
import { Icon } from "../../components/icon";
import { Button } from "../../components/button";
import { Header } from "../../components/header/header";
import { Results } from "./results";
import { ResultType } from "./result.type";

export function AccountCreation() {

    const [error, setError] = useState<string>();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const usernameInput = useRef<HTMLInputElement | null>(null);

    const [working, setWorking] = useState(false);
    const [data, setData] = useState<ResultType[]>([]);
    const onSubmit = useCallback((e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();

        setWorking(true);
        api.services.password({
            password: password
        }).then(r => r?.hash || null)
            .then((result) => {
                setData(prev => prev.concat([{
                    password: result,
                    username: username,
                    clearTextPassword: password
                }]));
            })
            .then(() => {
                setUsername('');
                setPassword('');
                usernameInput.current?.focus();
            })
            .finally(() => {
                setWorking(false);
            });
    }, [password, username]);
    const clearData = useCallback(() => {
        setData([]);
    }, []);


    useEffect(() => {
        if (data.find(d => d.username === username)) {
            setError(`'${username}' is already scripted for creation`);
            return;
        }
        if (username.startsWith('S')) {
            if (!username.startsWith('S-') || isNaN(+username.substring(2)) || username.length < 3) {
                setError('School Logins must follow the format S-[Lookup ID]');
                return;
            }
        } else {
            if (isNaN(+username)) {
                setError('Congregation Logins must be Lookup IDs');
                return;
            }
        }

        setError(undefined);
    }, [data, username]);

    return (
        <form onSubmit={onSubmit} className="d-flex h-100">
            <Flex justification="between" className={'content ' + (data.length ? 'has-results' : '')}>
                <Flex justification="center" className="data-entry">
                    <Flex column className="rounded shadow-sm border bg-white text-dark px-3 py-2">
                        <div>
                            <h3 className="fw-light">Enter Account Username and Password</h3>
                        </div>
                        <div className="mb-3">
                            <label>Username</label>

                            <input type="text" ref={usernameInput} className="form-control"
                                value={username} onChange={e => setUsername(e.target.value.toUpperCase())} />

                            {error ? <div style={{ fontSize: 10 }} className="text-danger mt-1">
                                {error}
                            </div> : <div style={{ fontSize: 10 }} className="text-dark opacity-75 mt-1">
                                Prefix username with S- for school account
                            </div>}
                        </div>
                        <div className="mb-3">
                            <label>Password</label>

                            <input type="text" className="form-control"
                                value={password} onChange={e => setPassword(e.target.value)} />
                        </div>

                        <div className="flex-grow-1"></div>
                        <div className="d-grid">
                            <Button submit
                                disabled={!username || !password || working || !!error}
                                className="btn btn-success">
                                <Icon icon="database" text="Generate SQL" />
                            </Button>
                        </div>
                    </Flex>
                </Flex>
                <div className="flex-grow-1">
                    <Flex column className="h-100">
                        <Header />
                        <Results results={data} clear={clearData} />
                    </Flex>
                </div>
            </Flex>
        </form>
    );
}
