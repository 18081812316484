import { Callback, filterEmpties } from "@lcms/helpers";
import { useCopyToClipboard } from "@lcms/react-helpers";
import { useCallback, useMemo, useState } from "react";
import { Flex } from "../../components/flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "../../components/icon";
import { Button } from "../../components/button";
import { Result } from "./result";
import { getSql } from "./get-sql";
import { api } from "../../api";
import { ResultType } from "./result.type";
import { ResultWithStateType } from "./result-with-state.type";

export function Results({ results, clear }: { results: ResultType[]; clear: Callback; }) {



    const [successes, setSuccesses] = useState<string[]>([]);
    const [failures, setFailures] = useState<string[]>([]);

    const resultsWithState: ResultWithStateType[] = useMemo(() => results.map(r => {
        return {
            ...r,
            state: successes.find(s => r.username === s) ? 'saved' : failures.find(f => f === r.username) ? 'failed' : undefined,
            sql: r.password === null ? null : getSql(r.username, r.password)
        }
    }), [failures, results, successes])

    const all = useMemo(() => filterEmpties(resultsWithState.map(r => r.sql)).join('\n'), [resultsWithState]);

    const { clipboardIsSupported, copied, copy } = useCopyToClipboard(all);

    const [running, setRunning] = useState(false);
    const run = useCallback(() => {
        setRunning(true)
        api.services.createLogins({
            logins: results.filter(r => r.password !== null && !successes.find(s => s === r.username)) as { username: string; password: string }[]
        }).then(result => {
            if (result) {
                setSuccesses(prev => prev.concat(result.completed));
                setFailures(result.failed)
            }
        }).finally(() => {
            setRunning(false)
        })
    }, [results, successes]);

    return (
        <div className="results overflow-auto">
            <div className="bg-white border shadow rounded">
                <div className="pb-2 h-100" style={{ overflowY: 'auto' }}>
                    <Flex justification="between" alignment="baseline" className="px-3 bg-white border-bottom position-sticky top-0">
                        <Flex alignment="center">
                            {clipboardIsSupported && <div className="me-2">
                                <Button onClick={copy} className={"btn-sm " + (copied ? "btn-success" : 'btn-dark')}>
                                    <Icon icon="clipboard" text={copied ? "Copied!" : "Copy SQL"} />
                                </Button></div>}
                            <div className="me-2">
                                <Button disabled={running} onClick={run} className="btn-sm btn-success">
                                    <Icon icon="square-terminal" text="Run SQL" />
                                </Button></div>
                        </Flex>

                        <Button style={{ fontSize: 30 }} className="btn-link text-decoration-none text-dark" onClick={clear}>
                            <FontAwesomeIcon icon={['fass', 'times']} />
                        </Button>
                    </Flex>
                    {resultsWithState.map((query, i) => <Result key={i} result={query} />)}
                </div>
            </div>
        </div>);
}
