import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AzureUser } from "@lcms/identity-microsoft";
import { useAuthentication } from "@lcms/react-identity";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "../../components/flex";
import { Header } from "../../components/header/header";
import { isAdmin } from "./is-authorized";

export function NotAuthorized() {
    const { push } = useHistory();
    const authentication = useAuthentication<AzureUser>();
    useEffect(() => {
        if (authentication && isAdmin(authentication.user)) {
            push('/')
        }
    }, [authentication, push])

    return (
        <>
            <Header />
            <div>
                <Flex justification="center" alignment="center">
                    <div className="bg-white border shadow rounded text-center px-5 py-5">
                        <FontAwesomeIcon style={{ fontSize: 75 }} className="text-danger" icon={['fass', 'ban']} />
                        <div className="mt-2">
                            You do not have access to this application
                        </div>
                        <div className="text-dark fw-light">
                            If you believe you need access to this application, please contact help desk
                        </div>
                    </div>
                </Flex>
            </div>
        </>
    );
}
