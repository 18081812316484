import { useCopyToClipboard } from "@lcms/react-helpers";
import { Flex } from "../../components/flex";
import { Icon } from "../../components/icon";
import { Button } from "../../components/button";
import { ResultWithStateType } from "./result-with-state.type";


export function Result({ result }: { result: ResultWithStateType }) {
    const { sql, state, clearTextPassword, username } = result;
    const { copy, clipboardIsSupported, copied } = useCopyToClipboard(sql || '');

    if (sql === null) {
        return <pre className="border rounded-1 bg-secondary text-danger">
            Error Hashing Password
        </pre>;
    }

    return (
        <div className={"border-start border-4 " + (state === 'failed' ? 'border-danger' : state === 'saved' ? 'border-success' : 'border-white')}>
            <Flex justification="between" className="my-1 px-2 text-dark ">
                <div className="fw-light">
                    Create Account for {username}
                </div>
                <div>
                    <small className="fw-normal">
                        Password: {clearTextPassword}
                    </small>
                </div>
            </Flex>

            <Flex alignment="center" justification="between" className="px-2">
                {clipboardIsSupported && <div className="me-2">
                    <Button onClick={copy} className={"btn-sm " + (copied ? "btn-success" : 'btn-outline-dark')}>
                        <Icon icon="clipboard" text={copied ? "Copied!" : "Copy"} />
                    </Button></div>}
                <div className="px-1 bg-secondary text-dark py-2 flex-grow-1">
                    <pre className="m-0">
                        {sql}
                    </pre>
                </div>
            </Flex>
        </div>
    );
}
