import { AuthenticationProvider } from "@lcms/react-identity";
import { PropsWithChildren } from "react";
import { LoadingPage } from "../../components/loading/loading-page";
import { authenticationConfig } from "./authentication-config";

export function Authentication({ children }: PropsWithChildren<{}>) {
    return (
        <AuthenticationProvider {...authenticationConfig} LoadingPage={<LoadingPage />}>
            {children}
        </AuthenticationProvider >
    )
}