import { ApiCall, ParameterTypes } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export interface EndpointResponse {
    completed: string[];
    failed: string[];
}
export const endpoint = new ApiCall<EndpointResponse>().post('createLogins', {
    route: endpoints.createLogins,
    params: {
        logins: {
            type: [{
                username: ParameterTypes.String,
                password: ParameterTypes.String
            }]
        }
    }
});
export const createLoginsEndpoint = endpoint;