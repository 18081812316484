import { library } from "@fortawesome/fontawesome-svg-core";
import { faDatabase, faArrowRightFromBracket, faTimes, faClipboard, faSquareTerminal, faBan } from "@fortawesome/sharp-solid-svg-icons";

library.add(
    faTimes,
    faDatabase,
    faArrowRightFromBracket,
    faClipboard,
    faSquareTerminal,
    faBan
);
