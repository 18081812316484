import React from 'react';
import { Authentication } from '../features/authentication/authentication';
import { MainContent } from './main-content';

function App() {
  return (
    <Authentication>
      <MainContent />
    </Authentication>
  );
}

export default App;
