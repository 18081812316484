import { AzureUser } from "@lcms/identity-microsoft";
import { useAsyncState } from "@lcms/react-helpers";
import { useAuthentication } from "@lcms/react-identity";
import React, { useMemo } from "react";
import { Flex } from "../flex";
import { getImage } from "../../features/account-creation/get-image";


export function UserImage() {
    const [image] = useAsyncState(getImage);
    const user = useAuthentication<AzureUser>();

    const initials = useMemo(() => user?.user.username.split(' ').map(c => c[0].toUpperCase()).filter(c => c >= 'A' && c <= 'Z').join(''), [user?.user.username]);

    if (image)
        return <img src={image} alt="User" className="rounded-circle border border-secondary border-2" style={{ width: 'calc(3em - 2px)', height: 'calc(3em - 2px)' }} />;

    if (initials) {
        return <Flex alignment="center" justification="center" className="rounded-circle border border-secondary border-2 bg-dark text-white" style={{ width: 'calc(3em - 2px)', height: 'calc(3em - 2px)' }}>
            <div>
                {initials}
            </div>
        </Flex>;
    }
    return null;
}
