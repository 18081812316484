import { ApiCall, ParameterDefinition } from '@lcms/api-definition';
import { endpoints } from '../../endpoints';

export interface EndpointResponse {
    hash: string;
}
export const endpoint = new ApiCall<EndpointResponse>().post('password', {
    route: endpoints.hashPassword,
    params: {
        password: ParameterDefinition.String
    }
});
export const passwordEndpoint = endpoint;