import { AuthenticatedUser } from "@lcms/identity";
import { AzureUser } from "@lcms/identity-microsoft";

export function isAdmin(user: { roles: { code: string }[] }){
    return !!user.roles.find(r => r.code.indexOf('ADMIN') >= 0);
}

export function isAuthorized(authentication: AuthenticatedUser<AzureUser> | null) {
    if (!authentication) {
        return {
            redirect: '/login'
        };
    } else if (!isAdmin(authentication.user)) {
        return {
            redirect: '/not-authorized'
        };
    }
    return null;
}
