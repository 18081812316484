
import { HTMLAttributes, PropsWithChildren, useMemo } from "react";
import { Alignment } from "./alignment";
import { Justification } from "./justification";

type DivProps = React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface FlexProps {
    column?: boolean;
    alignment?: Alignment;
    justification?: Justification;
    grow?: boolean;
    wrap?: boolean;
}

export function Flex({ alignment, children, column, justification, grow, className, wrap, ...divProps }: PropsWithChildren<FlexProps> & DivProps) {
    const flexClass = useMemo(() => [
        'd-flex',
        justification ? 'justify-content-' + justification : null,
        alignment ? 'align-items-' + alignment : null,
        column ? 'flex-column' : null,
        grow ? 'flex-grow-1' : null,
        wrap ? 'flex-wrap' : null,
        className
    ]
        .filter(x => x)
        .join(' '), [alignment, className, column, grow, justification, wrap])

    return (
        <div className={flexClass} {...divProps}>
            {children}
        </div>
    );
}