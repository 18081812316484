import { AuthorizedRoute, ImplicitLoginPage } from "@lcms/react-identity"
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AccountCreation } from "../features/account-creation";
import { NotAuthorized } from "../features/authorization/not-authorized";
import { isAuthorized } from "../features/authorization/is-authorized";

export function MainContent() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" render={() => <ImplicitLoginPage defaultRoute="/" />} />
                <Route path="/not-authorized" render={NotAuthorized} />
                <AuthorizedRoute path="/" render={Index}
                    redirect={null} isAuthorized={isAuthorized}
                />
            </Switch>
        </BrowserRouter>
    );
}

function Index() {
    return (
        <AccountCreation />
    )
}

