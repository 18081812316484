import React from "react";
import { Flex } from "../flex";
import { LogoutButton } from "../logout.button";
import { User } from "../user/user";


export function Header() {
    return (
        <Flex justification="between" className="header position-relative px-3 pb-3 pt-3 bg-primary text-white">
            <div><h1 className="fw-light">LISN School and Congregation Account Creation</h1></div>
            <div className="position-absolute backdrop bg-primary start-0 end-0 top-0"></div>
            <div>
                <Flex alignment="center">
                    <Flex column alignment="center">
                        <User />
                    </Flex>
                    <div className="ms-2">
                        <LogoutButton />
                    </div>
                </Flex>
            </div>
        </Flex>
    );
}
