import { useLogout } from "@lcms/react-identity";
import { Button } from "./button";
import { Icon } from "./icon";

export function LogoutButton() {

    const logout = useLogout()
    if (logout)
        return <Button className="btn-outline-secondary btn-sm rounded-circle border-0 d-flex justify-content-center align-items-center p-0 m-0" style={{width: '3em', height: '3em'}} onClick={logout}>
            <Icon icon='arrow-right-from-bracket' />
        </Button>
    return null;
}